<template>
  <div class="exercise-intro-wrapper">
    <c-loading v-if="!loaded"/>
    <div v-else class="background-items">
      <c-liana class="liana-left"/>

      <div class="intro-buddy-with-sword" v-if="!isTest">
        <img
            :src="
          'https://cdn.spellit.nl/buddies/' +
          profile.student.buddy_id +
          '/sword.png'
        "
            alt="buddy-with-sword"
            class="buddy-with-sword"
        />
      </div>

      <c-liana class="liana-right-1"/>
      <c-liana class="liana-right-2"/>
    </div>

    <component :is="getIntro" v-if="loaded"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import CLiana from "@/components/Liana";

export default {
  name: 'c-exercise-intro',
  components: {CLiana},
  computed: {
    ...mapGetters({
      getIntro: "template/getIntro",
      isTest: "router/isTest",
      profile: "user/getProfile",
      exercise: "exercise/getExercise",
    })
  },
  data: () => ({
    loaded: false
  }),
  mounted() {
    if (this.exercise && this.exercise._exercise_type._is_dictation) {
      this.$api.post('/api/v1/exercise/hard-reset/' + this.exercise.id, {"difficulty": this.exercise.getDifficulty()})
          .then(() => {
            this.$store.dispatch("exercise/fetchExercise", this.exercise.id);
            this.loaded = true;
          });
    } else {
      this.loaded = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.exercise-intro-wrapper {
  width: 100%;
  height: 100%;
}

.intro-buddy-with-sword {
  position: absolute;
  height: 50%;
  transform: scaleX(-1);
  right: 0;
  bottom: 2%;
  z-index: 1;
}

.background-items {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>